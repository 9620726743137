import React from "react";
import { Logo } from "../../Logo";
import { Styles } from "./style";
import { useLocation, useNavigate } from "react-router-dom";

const CustomDropdownHeader = ({ dropdownHeadings, logoAreaData }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (e, link) => {
    e.preventDefault();
    const [path, hash] = link.split("#");

    const scrollToElement = () => {
      const element = document.getElementById(hash);
      if (element) {
        const headerHeight = 100; // Adjust this value based on your header height
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition =
          elementPosition + window.pageYOffset - headerHeight;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    };

    if (location.pathname === path) {
      scrollToElement();
    } else {
      navigate(link, { replace: true });
      setTimeout(scrollToElement, 100);
    }
  };

  return (
    <Styles logoAreaData={logoAreaData}>
      <div className="conference-header">
        {dropdownHeadings?.map((headingGroup, index) => (
          <div key={index} className="conference-header-item">
            {headingGroup.map((heading, index) => (
              <div key={index} className="conference-header-item-content">
                <h4 className="conference-header-item-title">
                  {heading.title}
                </h4>

                {heading.content?.length > 0 &&
                  heading.content.map((content, index) => (
                    <div
                      key={index}
                      className="conference-header-item-content-item"
                    >
                      <a
                        className="conference-header-item-content-item-link"
                        href={content.link}
                        onClick={(e) => handleNavigation(e, content.link)}
                      >
                        {content.title}
                      </a>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        ))}

        <div className="logo-area">
          <Logo type="dark" />
          <div className="conference-header-item-wrapper">
            <h4 className="conference-header-item-title">
              {logoAreaData.title}
            </h4>
            <h4 className="conference-header-item-title year">
              {logoAreaData.subtitle}
            </h4>
            <span className="conference-header-item-subtitle">
              {logoAreaData.paragraph}
            </span>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default CustomDropdownHeader;
