export const ConferencePageHeadings = [
  [
    {
      title: "Conference",
      content: [
        {
          title: "About Conference",
          link: "/conference#about",
        },
        {
          title: "Program Committee",
          link: "/conference#comittee",
        },
      ],
    },
    {
      title: "Program",
      content: [
        {
          title: "Scientific Program",
          link: "/conference#thematic",
        },
        {
          title: "Speakers",
          link: "/conference#speakers",
        },
        {
          title: "Workshops",
          link: "/conference#workshops",
        },
      ],
    },
  ],
  [
    {
      title: "Call for Abstract",
      content: [
        {
          title: "Abstract Submission Instruction",
          link: "/conference#instruction",
        },
        {
          title: "Submission Deadline",
          link: "/conference#deadline",
        },
      ],
    },
    {
      title: "Registration",
      content: [
        {
          title: "Conference Registration",
          link: "/conference#registration",
        },
        {
          title: "Workshop Registration",
          link: "/conference#workshop-registration",
        },
      ],
    },
  ],
  [
    {
      title: "Awards and Special Issue",
      content: [
        {
          title: "Awards",
          link: "/conference#awards",
        },
        {
          title: "Special Issue",
          link: "/conference#special-issue",
        },
      ],
    },
    {
      title: "Sponsors and Venue",
      content: [
        { title: "Sponsors", link: "/conference#sponsors" },
        { title: "Venue", link: "/conference#top" },
      ],
    },
  ],
];

export const StudentsPageHeadings = [
  [
    {
      title: "Student Life",
      content: [
        { title: "Student Life at PTU", link: "/student-life" },
        {
          title: "Accommodation",
          link: "/accommodation",
        },
        {
          title: "Tuition Fees",
          link: "/tuition-fees",
        },
      ],
    },
    {
      title: "Student Support",
      content: [
        {
          title: "Student Support Service",
          link: "/student-support-service",
        },
        {
          title: "Student Support Service - Contact Us",
          link: "/student-support-service-contact-us",
        },
        {
          title: "Student Information and Support Section – PTU Assists",
          link: "/student-information-support",
        },
      ],
    },
  ],
  [
    {
      title: "International",
      content: [
        { title: "PTU welcomes", link: "/international" },
        { title: "Arrival and Accomodation", link: "/international#arrival" },
        {
          title: "Registration and Customs",
          link: "/international#registration",
        },
        { title: "Social Life", link: "/international#social" },
        { title: "Living in Uzbekistan", link: "/international#living" },
      ],
    },
    {
      title: "Student Union and Voice",
      content: [
        {
          title: "Students Union",
          link: "/students-union",
        },
        {
          title: "Student Voice",
          link: "/student-voice",
        },
      ],
    },
  ],
  [
    {
      title: "Wellbeing",
      content: [
        { title: "Wellbeing", link: "/wellbeing" },
        { title: "Social Engagement - Clubs", link: "/social" },
      ],
    },
    {
      title: "Academic Regulations",
      content: [
        {
          title: "UoS Academic Regulations",
          link: "/academic-regulations#uos",
        },
        {
          title: "DMU Academic Regulations",
          link: "/academic-regulations#dmu",
        },
      ],
    },
  ],
];
